import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shipping-delivery-policy-page',
    templateUrl: './shipping-delivery-policy-page.component.html',
    styleUrls: ['./shipping-delivery-policy-page.component.scss']
})
export class ShippingDeliveryPolicyPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Shipping and Delivery Policy'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Q1. What is Werkbite Shipping and Delivery Policy?',
            answer: "Werkbite Pvt Ltd is committed to ensuring timely and reliable shipping and delivery for all our B2B and B2C in-house products. Our policy is designed to provide a seamless experience by partnering with trusted logistics providers, ensuring safe and prompt delivery to your doorstep. We aim to set clear expectations regarding delivery timelines, costs, and any special handling requirements."
        },
        {
            questions: 'Q2. How are shipping charges calculated for orders?',
            answer: "Shipping charges are calculated based on the weight, dimensions, and destination of the package. For standard delivery, charges are nominal and clearly displayed at checkout. Free shipping may be applicable on orders exceeding a certain value, as outlined in specific promotional offers. For expedited or international deliveries, additional charges may apply, and customers are informed of these costs before completing their purchase."
        },
        {
            questions: 'Q3. What are the estimated delivery timelines?',
            answer: "Delivery timelines depend on the shipping destination and product availability. For domestic deliveries, products are typically delivered within 3–7 business days, while international orders may take 10–15 business days. Custom or made-to-order items may require additional processing time, which will be communicated at the time of purchase. Werkbite ensures transparency by providing tracking details for all orders."
        },
        {
            questions: 'Q4. Does Werkbite offer international shipping?',
            answer: "Yes, Werkbite Pvt Ltd offers international shipping to select countries. Our logistics network is designed to ensure safe and timely deliveries globally. However, shipping fees, customs duties, and local taxes for international orders are borne by the customer and vary depending on the destination country. Detailed information about these charges will be shared at checkout. "
        },
        {
            questions: 'Q5. What happens if a package is delayed or lost in transit?',
            answer: "In the rare event of a delay or loss, Werkbite Pvt Ltd works closely with our logistics partners to resolve the issue promptly. Customers are advised to report delayed or missing shipments to our customer support team within 7 days of the estimated delivery date. We will initiate an investigation and either provide a replacement or a full refund, depending on the situation."
        },
        {
            questions: 'Q6. Can customers track their orders?',
            answer: "Absolutely! Werkbite provides order tracking for all shipments. Once your order is dispatched, you will receive a confirmation email or SMS with a tracking number and a link to the logistics partner’s website. This ensures that customers can monitor the status of their shipment in real time, from dispatch to delivery."
        }
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    answer : string;
}