import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-refund-policy-page',
    templateUrl: './refund-policy-page.component.html',
    styleUrls: ['./refund-policy-page.component.scss']
})
export class RefundPolicyPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Q&A format for the Refund Policy'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Q1. What is Werkbite’s Refund Policy?',
            answer: "Werkbite Pvt Ltd is dedicated to ensuring customer satisfaction by offering a transparent and hassle-free refund process. Our policy outlines the conditions under which refunds are applicable, the timeline for processing them, and the methods of reimbursement. We strive to resolve refund requests promptly and ensure a smooth experience for our customers."
        },
        {
            questions: 'Q2. Under what conditions can I request a refund?',
            answer: "Refunds are applicable in the following scenarios <br> The product received is defective, damaged, or significantly different from its description. <br> The order was canceled before shipment. <br> The product is returned in its original condition and packaging within the specified return window. <br> Please note that certain items, such as perishable goods or customized products, may not be eligible for refunds."
        },
        {
            questions: 'Q3. How do I initiate a refund request?',
            answer: "To initiate a refund, customers are required to contact our customer support team via email or phone, providing their order details, proof of purchase, and any supporting evidence such as photos of the damaged or defective product. Our team will review the request and guide you through the next steps, including arranging for a return pickup if applicable."
        },
        {
            questions: 'Q4. What is the timeframe for processing refunds?',
            answer: "Refunds are typically processed within 7–10 business days from the date of product return or approval of the refund request. The actual time for the refunded amount to reflect in your account depends on the payment method used during purchase. For instance, credit card refunds may take additional time due to bank processing. "
        },
        {
            questions: 'Q5. How will the refund amount be reimbursed?',
            answer: "Refunds are issued using the original payment method. For example: <br> Payments made via credit/debit cards will be credited back to the same card. <br> Orders paid through wallets or UPI will be refunded to the respective wallet or UPI ID. <br> For cash-on-delivery orders, refunds will be issued as store credits or directly transferred to the customer's bank account, as per the customer preference."
        },
        {
            questions: 'Q5. Are there any charges or deductions during a refund?',
            answer: "Refunds are generally processed without any additional charges. However, in certain cases, such as non-defective returns or order cancellations after shipment, nominal charges may be deducted to cover shipping or restocking fees. Customers will be informed of such charges before the refund is processed."
        }
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    answer : string;
}