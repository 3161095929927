import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cancellation-policy-page',
    templateUrl: './cancellation-policy-page.component.html',
    styleUrls: ['./cancellation-policy-page.component.scss']
})
export class CancellationPolicyPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Q&A format for the Cancellation Policy'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Q1. What is Werkbite’s Cancellation Policy?',
            answer: "Werkbite Pvt Ltd aims to provide flexibility to its customers by allowing order cancellations under specific conditions. Customers can cancel their orders before they are shipped, and the full amount will be refunded. Our cancellation policy ensures transparency and customer convenience while balancing operational requirements."
        },
        {
            questions: 'Q2. When can I cancel an order?',
            answer: "Orders can be canceled before they are dispatched from our warehouse. Once the product has been shipped, cancellations are no longer possible, but customers may opt for a return or refund under our Refund Policy. Cancellation requests should be made promptly to avoid unnecessary delays."
        },
        {
            questions: 'Q3. How do I cancel an order?',
            answer: "To cancel an order, customers can log into their account on our website or app, navigate to the My Orders section, and select the cancellation option for the desired order. Alternatively, customers can contact our customer support team with their order details to process the cancellation on their behalf."
        },
        {
            questions: 'Q4. Will I be charged for canceling an order?',
            answer: "No charges are applied for cancellations made before the order is shipped. However, if a cancellation is requested after the product has been dispatched, any incurred shipping costs may be deducted from the refund. Customers will be notified of any applicable deductions in advance."
        },
        {
            questions: 'Q5. What happens if the order is canceled after payment?',
            answer: "If an order is canceled after payment but before shipping, the full amount will be refunded to the original payment method. For cash-on-delivery orders, customers will not incur any charges. Refunds are processed promptly, typically within 7–10 business days, depending on the payment gateway or bank."
        },
        {
            questions: 'Q6. Are there any exceptions to the cancellation policy?',
            answer: "Certain products, such as custom-made, perishable, or special-order items, may not be eligible for cancellation once the order is confirmed. Additionally, promotions or flash sales may have specific cancellation restrictions, which will be clearly stated at the time of purchase."
        }
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    answer : string;
}